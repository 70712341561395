import { useEffect, useRef, useState } from "react";

const Xs = [];
const Ys = [];
const textWidths = [];
const textHeights = [];

function drawHeatmap(
  ctx,
  canvas,
  matrix,
  alunos,
  questoes,
  clustered,
  assessment,
  exam,
  school,
  classe,
  title,
  ordenar,
  ordem,
  method,
  scoresRow,
  scoresColumn,
  selectedQuestion,
  selectedStudent
) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const newWidth = screenWidth * 0.67;
  const newHeight = screenHeight * 0.84;

  // Define as novas dimensões para o canvas
  canvas.width = newWidth;
  canvas.height = newHeight;

  canvas.style.bordem = "none";
  canvas.style.boxShadow = "none";
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const numRows = matrix.length;
  const numCols = matrix[0].length;

  const labelWidthAlunos = calculateLabelWidth(ctx, alunos);
  const labelHeightQuestoes = 20 + 20;
  const widthFactor = scoresRow.length > 0 ? 60 : 0;
  const cellWidth = (canvas.width - labelWidthAlunos - widthFactor) / numCols;
  const heightFactor = scoresColumn.length > 0 ? 60 : 30;
  const cellHeight =
    (canvas.height - labelHeightQuestoes - heightFactor) / numRows;

  // Avaliação, Exame e Questões
  ctx.fillStyle = "#00A0EA";
  ctx.textAlign = "center";
  ctx.font = "400 13px Roboto";
  ctx.textBaseline = "middle";
  const text1 = `QUESTÕES:  ${assessment}  -  EXAME ${exam ? exam : ""}`;
  const text1Width = ctx.measureText(text1).width;
  ctx.fillText(text1.toUpperCase(), canvas.width / 2, 6);
  if (scoresRow.length > 1) ctx.fillText("SCORES", canvas.width - 25, 6);
  if (scoresColumn.length > 1) ctx.fillText("SCORES", 33, canvas.height - 35);

  // Escola, Turma e Alunos
  ctx.save();
  ctx.fillStyle = "#00A0EA";
  ctx.textAlign = "center";
  ctx.font = "400 13px Roboto";
  ctx.textBaseline = "middle";
  const text2 = `ALUNOS:  ${school}  -  TURMA ${classe}`;
  const text2Width = ctx.measureText(text2).width;
  ctx.translate(0, (cellHeight * numRows)/2);
  ctx.rotate(-Math.PI / 2);
  ctx.fillText(text2.toUpperCase(), 0, 10);
  ctx.restore();

  // Loop para desenhar os rótulos das questões na parte superior
  ctx.textAlign = "center";
  for (let i = 0; i < numCols; i++) {
    const x = labelWidthAlunos + i * cellWidth + cellWidth / 2;
    Xs.push(x);
    Ys.push(labelHeightQuestoes / 1.5);
    // Definindo estilos
    ctx.fillStyle = "#094F7B";
    ctx.textAlign = "center";
    ctx.font = `${questoes[i] == selectedQuestion ? "800" : "400"} 13px Inter`;
    ctx.textBaseline = "middle";
    if (clustered === "y" || clustered === false) {
      ctx.fillText(questoes[i], x, labelHeightQuestoes / 1.5);
      ctx.fillText(
        scoresColumn[i] ? scoresColumn[i] : "",
        x,
        canvas.height - 35
      );
    }
    textWidths.push(ctx.measureText(questoes[i]).width);
    textHeights.push(parseInt(ctx.font));
  }
  // Ajuste da posição do heatmap para acomodar os rótulos das questões
  ctx.translate(labelWidthAlunos, labelHeightQuestoes);

  // Loop para desenhar os rótulos dos alunos e o heatmap
  for (let i = 0; i < numRows; i++) {
    // Definindo estilos
    ctx.fillStyle = "#094F7B";
    ctx.textAlign = "center";
    ctx.font = `${alunos[i] == selectedStudent ? "800" : "400"} 13px Inter`;
    ctx.textBaseline = "middle";
    if (clustered === "x" || clustered === false) {
      ctx.fillText(alunos[i], 0 - 25, i * cellHeight + (cellHeight * 0.5));
      ctx.fillText(
        scoresRow[i],
        canvas.width - 90,
        i * cellHeight + cellHeight / 1.5
      );
      Xs.push(labelWidthAlunos - 25);
      Ys.push(i * cellHeight + cellHeight / 1.5 + labelHeightQuestoes);
      textWidths.push(ctx.measureText(alunos[i]).width);
      textHeights.push(parseInt(ctx.font));
    }

    for (let j = 0; j < numCols; j++) {
      const x = j * cellWidth;
      const y = i * cellHeight;

      if (matrix[i][j] === 1) {
        if (method == "score") {
          ctx.fillStyle = "rgba(241, 83, 15, 1)";
        } else {
          if (ordenar == "students")
            ordem == "ascending"
              ? (ctx.fillStyle = "rgba(0, 204, 108, 1)")
              : (ctx.fillStyle = "rgba(165, 0, 38, 1)");
          if (ordenar == "questions")
            ordem == "ascending"
              ? (ctx.fillStyle = "rgba(165, 0, 38, 1)")
              : (ctx.fillStyle = "rgba(0, 204, 108, 1)");
        }
      } else if (matrix[i][j] === 2) {
        if (method == "score") {
          ctx.fillStyle = "rgba(241, 196, 15, 1)";
        } else {
          if (ordenar == "students")
            ordem == "ascending"
              ? (ctx.fillStyle = "rgba(0, 153, 81, 1)")
              : (ctx.fillStyle = "rgba(255, 102, 138, 1)");

          if (ordenar == "questions")
            ordem == "ascending"
              ? (ctx.fillStyle = "rgba(255, 102, 138, 1)")
              : (ctx.fillStyle = "rgba(0, 153, 81, 1)");
        }
      } else if (matrix[i][j] === 3) {
        if (method == "score") {
          ctx.fillStyle = "rgba(1, 230, 118, 1)";
        } else {
          if (ordenar == "students")
            ordem == "ascending"
              ? (ctx.fillStyle = "rgba(0, 104, 55, 1)")
              : (ctx.fillStyle = "rgba(255, 204, 216, 1)");

          if (ordenar == "questions")
            ordem == "ascending"
              ? (ctx.fillStyle = "rgba(255, 204, 216, 1)")
              : (ctx.fillStyle = "rgba(0, 104, 55, 1)");
        }
      }

      // Desenha o quadrado com o contorno preto
      ctx.fillRect(x, y, cellWidth, cellHeight);
      ctx.strokeStyle = "white";
      ctx.lineWidth = 0.3;
      ctx.strokeRect(x, y, cellWidth, cellHeight);
    }
  }

  let colors;

  if (method === "score") {
    colors = [
      "rgba(1, 230, 118, 1)",
      "rgba(241, 196, 15, 1)",
      "rgba(241, 83, 15, 1)",
    ];
  } else {
    if (method === "categorico" && ordem === "ascending") {
      if (ordenar == "students")
        colors = [
          "rgba(0, 104, 55, 1)",
          "rgba(0, 153, 81, 1)",
          "rgba(0, 204, 108, 1)",
        ];

      if (ordenar == "questions")
        colors = [
          "rgba(255, 204, 216, 1)",
          "rgba(255, 102, 138, 1)",
          "rgba(165, 0, 38, 1)",
        ];
    } else {
      if (ordenar == "students")
        colors = [
          "rgba(255, 204, 216, 1)",
          "rgba(255, 102, 138, 1)",
          "rgba(165, 0, 38, 1)",
        ];

      if (ordenar == "questions")
        colors = [
          "rgba(0, 104, 55, 1)",
          "rgba(0, 153, 81, 1)",
          "rgba(0, 204, 108, 1)",
        ];
    }
  }

  const concepts = ["Suficiente", "Parcialmente Suficiente", "Insuficiente"];
  const squareSize = 20;

  ctx.fillStyle = "#000";
  ctx.font = "600 12px Inter";

  let startX = canvas.width - ctx.measureText(concepts[2]).width * 1.43;
  let startY = canvas.height - 57;

  ctx.fillText(concepts[2], startX, startY + squareSize / 2);

  startX -= 60;

  ctx.fillStyle = colors[2];
  ctx.fillRect(startX, startY, squareSize, squareSize);

  ctx.fillStyle = "#000";
  ctx.font = "600 12px Inter";
  startX -= ctx.measureText(concepts[1]).width * 0.6;
  ctx.fillText(concepts[1], startX, startY + squareSize / 2);

  startX -= 95;
  ctx.fillStyle = colors[1];
  ctx.fillRect(startX, startY, squareSize, squareSize);

  ctx.fillStyle = "#000";
  ctx.font = "600 12px Inter";
  startX -= ctx.measureText(concepts[0]).width * 1.4;
  ctx.fillText(concepts[1], startX, startY + squareSize / 2);

  startX -= 95;
  ctx.fillStyle = colors[0];
  ctx.fillRect(startX, startY, squareSize, squareSize);

  ctx.textAlign = "start";

  ctx.fillStyle = "#000";
  ctx.font = "700 12px Inter";
  ctx.fillText(title, 0, startY + squareSize / 2);




  // Resetar a transformação para evitar problemas futuros
  ctx.setTransform(1, 0, 0, 1, 0, 0);
}

function calculateLabelWidth(ctx, labels) {
  let width = 0;
  ctx.font = "12px Arial"; // Defina a fonte e o tamanho dos rótulos dos alunos

  labels.forEach((label) => {
    const labelWidth = ctx.measureText(label).width;
    if (labelWidth > width) {
      width = labelWidth;
    }
  });

  return width + 35;
}

const Heatmap = ({
  students,
  questions,
  studentQuestions,
  assessment,
  exam,
  school,
  classe,
  clustered = false,
  method = "score",
  ordenar,
  ordem,
  download,
  setDownload,
  title,
  selectedQuestion,
  setSelectedQuestion,
  selectedStudent,
  setSelectedStudent,
  scoresRow,
  scoresColumn,
}) => {
  const canvasRef = useRef(null);
  let canvas;

  useEffect(() => {
    canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    function handleCanvasClick(event) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      // Verificar se o clique está nos textos horizontais (questions)
      for (let i = 0; i < questions.length; i++) {
        const xQuestion = Xs[i];
        const yQuestion = Ys[i];
        const widthQuestion = textWidths[i];
        const heightQuestion = textHeights[i];
        const textQuestion = questions[i];
        if (
          mouseX >= xQuestion - widthQuestion / 2 &&
          mouseX <= xQuestion + widthQuestion / 2 &&
          mouseY >= yQuestion - heightQuestion / 2 &&
          mouseY <= yQuestion + heightQuestion / 2
        ) {
          setSelectedQuestion(textQuestion);
          return; // Não precisa continuar procurando após encontrar um clique
        }
      }

      // Verificar se o clique está nos textos verticais (students)
      for (let i = 0; i < students.length; i++) {
        const xStudent = Xs[questions.length + i];
        const yStudent = Ys[questions.length + i];
        const widthStudent = textWidths[questions.length + i];
        const heightStudent = textHeights[questions.length + i];
        const textStudent = students[i];
        if (
          mouseX >= xStudent - widthStudent / 2 &&
          mouseX <= xStudent + widthStudent / 2 &&
          mouseY >= yStudent - heightStudent / 2 &&
          mouseY <= yStudent + heightStudent / 2
        ) {
          setSelectedStudent(textStudent);
          return;
        }
      }
    }

    canvas.addEventListener("click", handleCanvasClick);

    drawHeatmap(
      ctx,
      canvas,
      studentQuestions,
      students,
      questions,
      clustered,
      assessment,
      exam,
      school,
      classe,
      title,
      ordenar,
      ordem,
      method,
      scoresRow,
      scoresColumn,
      selectedQuestion,
      selectedStudent
    );

    if (download) {
      const link = document.createElement("a");
      link.download = "heatmap.png"; // Nome do arquivo a ser baixado

      // Criar uma nova imagem a partir dos dados do canvas
      const image = new Image();
      image.src = canvas.toDataURL("image/png");

      // Evento de carregamento da imagem
      image.onload = function () {
        const imgCanvas = document.createElement("canvas");
        imgCanvas.width = canvas.width;
        imgCanvas.height = canvas.height;

        const imgCtx = imgCanvas.getContext("2d");
        imgCtx.drawImage(image, 0, 0);

        // Converter o canvas para um link para download
        link.href = imgCanvas.toDataURL("image/png");
        link.click();
        setDownload(false);
      };
    }

    return () => {
      canvas.removeEventListener("click", handleCanvasClick);
    };
  }, [
    students,
    questions,
    studentQuestions,
    download,
    selectedQuestion,
    selectedStudent,
  ]);

  return (
    <div>
      <canvas ref={canvasRef} id="heatmap" width="1000" height="600"></canvas>
    </div>
  );
};

export default Heatmap;
